import { Controller, useFormContext } from 'react-hook-form'
import { validate } from 'uuid'
import {
  FormField,
  FormFieldInline,
  FormControl,
  FormAny,
  FormSelectNative,
} from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import useAuthComponents from '@/pages/operate/hooks/useAuthComponents'
import InputSearchADUser from './InputSearchADUser'

const userTypes = [ 'user', 'app' ]

export default function Fields ({ scope }) {
  const intl = useIntl()
  const { checkAvailability } = useAuthComponents()

  const {
    formState: { errors },
    watch,
    setValue,
    clearErrors,
  } = useFormContext()

  const [
    adId,
    firstName,
    lastName,
    email,
    phone,
    userType,
  ] = watch([
    `${scope}.adId`,
    `${scope}.firstName`,
    `${scope}.lastName`,
    `${scope}.email`,
    `${scope}.phone`,
    `${scope}.userType`,
  ])

  return (
    <>
      { userType !== 'app' ? (
        <>
          <FormControl type="any" name={`${scope}.firstName`} />
          <FormControl type="any" name={`${scope}.lastName`} />
          <FormControl type="any" name={`${scope}.email`} />
          <FormControl type="any" name={`${scope}.phone`} />
        </>
      ) : null}

      <FormField
        label={intl.t('users.form.fields.type')}
        description={intl.t('users.form.fields.typeDescr')}
        errors={get(errors, `${scope}.userType`)}
      >
        <Controller
          name={`${scope}.userType`}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
          }}
          render={({ field, fieldState }) => (
            <FormSelectNative
              {...field}
              autocomplete="off"
              invalid={fieldState.invalid}
              options={userTypes.map(opt => ({ id: opt, name: opt }))}
            />
          )}
        />
      </FormField>

      {userType === 'app' ? (
        <FormField
          label={intl.t('form.fields.id')}
          description={intl.t('files.form.fields.idDescr')}
          errors={get(errors, `${scope}.adId`)}
        >
          <FormControl
            name={`${scope}.adId`}
            rules={{
              validate: (value) => {
                const str = String(value || '').trim()
                if (!str.length) {
                  return intl.t('form.errors.fieldRequired')
                }
                if (!validate(value)) {
                  return intl.t('form.errors.fieldUuid')
                }
                if (value.length > 36) {
                  return intl.t('form.errors.fieldMaxlen', { max: 36 })
                }
                return true
              },
            }}
          />
        </FormField>
      ) : null}

      {userType === 'app' ? (
        <FormField
          label={intl.t('form.fields.user')}
          description={intl.t('files.form.fields.userDescr')}
          errors={get(errors, `${scope}.firstName`)}
        >
          <FormControl
            name={`${scope}.firstName`}
            rules={{
              validate: (value) => {
                const str = String(value || '').trim()
                if (!str.length) {
                  return intl.t('form.errors.fieldRequired')
                }
                if (value.length > 36) {
                  return intl.t('form.errors.fieldMaxlen', { max: 36 })
                }
                return true
              },
            }}
          />
        </FormField>
      ) : (
        <FormField
          label={intl.t('users.form.fields.adId')}
          description={intl.t('users.form.fields.adIdDescr')}
          errors={get(errors, `${scope}.adId`)}
        >
          <Controller
            name={`${scope}.adId`}
            render={({ field: { onChange, ...field }, fieldState }) => (
              <>
                <FormAny {...field} />
                <InputSearchADUser
                  invalid={fieldState.invalid}
                  onChange={(data) => {
                    clearErrors(`${scope}.adId`)
                    setValue(`${scope}.adId`, data.id, { shouldDirty: true })
                    setValue(`${scope}.firstName`, data.firstName, { shouldDirty: true })
                    setValue(`${scope}.lastName`, data.lastName, { shouldDirty: true })
                    setValue(`${scope}.email`, data.email, { shouldDirty: true })
                    setValue(`${scope}.phone`, data.phone, { shouldDirty: true })
                  }}
                />
              </>
            )}
          />
        </FormField>
      )}

      {adId && userType === 'user' ? (
        <>
          {firstName ? (
            <FormField
              label={intl.t('users.form.fields.firstName')}
              errors={get(errors, `${scope}.firstName`)}
            >
              <span className="form-control text-truncate text-muted">{firstName}</span>
            </FormField>
          ) : null}

          {lastName ? (
            <FormField
              label={intl.t('users.form.fields.lastName')}
              errors={get(errors, `${scope}.lastName`)}
            >
              <span className="form-control text-truncate text-muted">{lastName}</span>
            </FormField>
          ) : null}

          {email ? (
            <FormField
              label={intl.t('users.form.fields.email')}
              errors={get(errors, `${scope}.email`)}
            >
              <span className="form-control text-truncate text-muted">{email}</span>
            </FormField>
          ) : null}

          {phone ? (
            <FormField
              label={intl.t('users.form.fields.phone')}
              errors={get(errors, `${scope}.phone`)}
            >
              <span className="form-control text-truncate text-muted">{phone}</span>
            </FormField>
          ) : null}
        </>
      ) : null}

      <FormFieldInline
        label={intl.t('users.form.fields.active')}
        description={intl.t('users.form.fields.activeDescr')}
        errors={get(errors, `${scope}.isActive`)}
      >
        <FormControl
          type="checkbox"
          name={`${scope}.isActive`}
        />
      </FormFieldInline>
      {checkAvailability() ? (
        <FormFieldInline
          label={intl.t('users.form.fields.assistance')}
          description={intl.t('users.form.fields.assistanceDescr')}
          errors={get(errors, `${scope}.needIframeLoginAssistance`)}
        >
          <FormControl
            type="checkbox"
            name={`${scope}.needIframeLoginAssistance`}
          />
        </FormFieldInline>
      ) : null}
    </>
  )
}
