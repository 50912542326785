import { forwardRef } from 'react'
import { useIntl } from '@wiz/intl'
import { Popup } from '@wiz/components'
import Link from '@/components/Link'
import Icon from '@/shared/icon'
import Loading from '@/shared/loading'
import useLicense from '../pages/operate/hooks/useLicense'

const LicensePopup = forwardRef(({ onClose, className }, ref) => {
  const intl = useIntl()
  const { confirmRequest } = useLicense()

  return (
    <Popup
      refDialog={ref}
      title={intl.t('licenseManagement.title.popup')}
      size="lg"
      classNameContent={className}
      onClose={onClose}
    >
      {confirmRequest.isLoading ? <Loading /> : null}
      <div className="my-5 text-center">
        {!confirmRequest.isSuccess ? (
          <>
            <div className="fw-bold">{intl.t('licenseManagement.popup.wantToProceed')}</div>
            <div>{intl.t('licenseManagement.popup.wantToProceedDescription')}</div>

          </>
        ) : (
          <>
            <Icon name="faCircleCheck" size="xl" className="mb-2" />
            <div className="fw-bold my-2">{intl.t('licenseManagement.popup.successTitle')}</div>
            <div>{intl.t('licenseManagement.popup.successDescription')}</div>
          </>
        )}
      </div>
      {!confirmRequest.isSuccess ? (
        <div className="d-flex align-items-center">
          <div className="d-flex flex-fill align-items-center">
            <Icon name="faFileCode" />
            <span className="ms-2">{intl.t('licenseManagement.title.moreInfo')}</span>
            <Link
              onClick={() => window?.open?.('https://docs.wizata.com/docs/licensing', '_blank')}
              className="ms-1 text-decoration-underline"
              title={intl.t('licenseManagement.title.docs')}
            >
              <span>{intl.t('licenseManagement.title.docs')}</span>
            </Link>
          </div>
          <button
            className="position-relative btn btn-fill-secondary-alt active"
            type="button"
            onClick={confirmRequest.mutateAsync}
          >
            {confirmRequest.isLoading ? <Loading /> : null}
            {intl.t('form.actions.confirm')}
          </button>
          <button
            type="button"
            className="position-relative btn btn-fill-secondary-alt ms-3"
            onClick={onClose}
          >
            {intl.t('form.actions.cancel')}
          </button>
        </div>
      ) : null}
    </Popup>
  )
})

export default LicensePopup
