import {
  useCallback,
  useRef,
  useMemo,
} from 'react'
import classnames from 'classnames'
import { useIntl } from '@wiz/intl'
import { DropdownMenu, useOnline } from '@wiz/components'
import { useRouter } from '@/router'
import { useAuth } from '@/auth'
import useSyncStatus from '@/hooks/useSyncStatus'
import { appEnv } from '@/config'
import { useGlobalExecute } from '@/context/GlobalExecuteProvider'
import useLicense from '@/pages/operate/hooks/useLicense'
import Link from '@/components/Link'
import IconStatus from './IconStatus'
import Userpic from './Userpic'
import classes from './ButtonUser.css'
import Icon from '../../shared/icon'
import Loading from '../../shared/loading'

const iconsByLicense = {
  standard: 'faBadgeCheck',
  pro: 'faCrown',
  enterprise: 'faBuildings',
}

const RoutesById = {
  dashboard: 'homepage',
  profile: 'profile',
  logout: 'logout',
}

export default function ButtonUser () {
  const refTarget = useRef()

  const {
    user, isEditingDashboard, setIsEditingDashboard, backendVersion,
  } = useGlobalExecute()

  const auth = useAuth()
  const router = useRouter()
  const intl = useIntl()
  const online = useOnline()
  const syncStatus = useSyncStatus()

  const { licenseManagement } = useLicense()

  const handleClick = useCallback(async ({ id }) => {
    if (isEditingDashboard) {
      await window.wizConfirm({ message: 't/components.leaveEdit' })
      setIsEditingDashboard(false)
    }

    if (id === 'relogin') {
      auth.relogin()
    } else if (RoutesById[id]) {
      router.push({ name: RoutesById[id] })
    }
  }, [ auth, router, isEditingDashboard, setIsEditingDashboard ])

  const options = useMemo(() => ([
    {
      content: () => (
        <div className="d-flex px-3 pt-2">
          <div>
            <Icon
              className="me-2"
              name={iconsByLicense[licenseManagement.data.licenseType] || 'faBadgeCheck'}
              size="lg"
            />
          </div>

          <div className="position-relative">
            {licenseManagement.isLoading ? <Loading /> : null}
            <div className="mb-1">
              <b>
                Wizata&nbsp;
                {licenseManagement.data.licenseName || 'Standard'}
                &nbsp;License
              </b>
            </div>
            <div>
              Version&nbsp;
              {appEnv.VERSION_SHORT}
&nbsp;
              <span className="opacity-50">
                (
                {backendVersion}
                )
              </span>
            </div>
          </div>
          <div className="ms-auto my-auto">
            <Link
              onClick={() => window?.open?.('https://docs.wizata.com/docs/licensing', '_blank')}
              className="btn btn-flat-secondary"
              title={intl.t('menu.tooltip.docs')}
            >
              <Icon name="faAngleRight" size="lg" />
            </Link>
          </div>
        </div>
      ),
    },
    { divider: true },
    {
      id: 'profile', name: intl.t('profile.titleMenu'), icon: 'faUser', iconSize: 'lg',
    },
    { divider: true },
    {
      id: 'logout', name: intl.t('user.logout'), icon: 'faArrowRightFromBracket', iconSize: 'lg',
    },
  ]), [
    intl,
    licenseManagement,
    backendVersion,
  ])

  return (
    <>
      <button
        ref={refTarget}
        type="button"
        className={classnames(
          classes.btnUser,
          { 'p-0 rounded-circle ms-1': user.avatar },
          'btn btn-text btn-flat-secondary',
        )}
        title={intl.t('menu.tooltip.user')}
      >
        <Userpic />

        <IconStatus
          className={classnames(classes.status, { [classes.avatar]: user.avatar })}
          syncStatus={syncStatus.status}
          online={online}
          size="xs"
        />
      </button>

      <DropdownMenu
        target={refTarget}
        options={options}
        className={classes.dropdown}
        placement="bottom-end"
        arrow
        autoclose
        width={300}
        onClick={handleClick}
      />
    </>
  )
}
