import {
  useRef, useState,
} from 'react'
import cx from 'classnames'
import { CustomScrollbars, useMobile } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import RunnersWidget from './RunnersWidget'
import classes from './index.module.css'
import LicensePopup from '../../shared/licensePopup'

const Runners = {
  pipeline: {
    title: 't/engineManagement.pipelines.title',
    type: 'pipeline',
    icon: 'wiz--menu--engine-management',
  },
  experiment: {
    title: 't/engineManagement.experiments.title',
    type: 'experiment',
    icon: 'wiz--menu--experiment',
  },
}

const EngineManagement = () => {
  const [ isPopupOpened, setIsPopupOpened ] = useState(false)

  const refDialog = useRef()

  const intl = useIntl()
  const isMobile = useMobile()

  return (
    <>
      <CustomScrollbars>
        <div className="p-4 w-100">
          <div className={cx(classes.widgets, 'mx-n2')}>
            {Object.values(Runners).map(rnr => (
              <div className={cx(classes.widget, 'px-2', {
                [classes.widgetMobile]: isMobile,
              })}
              >
                <RunnersWidget
                  entityType={rnr.type}
                  title={rnr.title}
                  titleIcon={rnr.icon}
                  onLicensePopupOpen={() => setIsPopupOpened(true)}
                />
              </div>
            ))}
          </div>
        </div>
      </CustomScrollbars>

      {isPopupOpened ? (
        <LicensePopup
          ref={refDialog}
          onClose={() => setIsPopupOpened(false)}
          className={classes.content}
        />
      ) : null}
    </>
  )
}

export default EngineManagement
