import PropTypes from 'prop-types'
import { useFormContext, Controller } from 'react-hook-form'
import { auth } from '@/auth'
import {
  FormField, FormControl, FormFieldInline, FormCheckbox, FormSelectNative,
} from '@wiz/components'
import FormTemplates from '@/components/Form/ListTemplates'
import { useIntl } from '@wiz/intl'
import { get, consts } from '@wiz/utils'
import ListBusinessLabels from '@/components/Form/ListBusinessLabels'
import useAuthComponents from '@/pages/operate/hooks/useAuthComponents'

// {
//   "name": "string", !
//   "order": 0, !
//   "templateId": "string", !
//   "labelId": "string",
//   "twinId": "string",
//   "ownerId": "string"
// }
const DEFAULT_VALUE_REGEXP = /^now([+-]\d+([yMwdHhms]{1,2}))?$/
const URL_REGEXP = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}|https?:\/\/localhost:\d{1,5})/gi

export default function Fields ({
  scope, isIframe, isGrafana, isStreamlit,
}) {
  const intl = useIntl()
  const { formState: { errors }, watch } = useFormContext()
  const { isPersonalStrictly, isOrganizational } = useAuthComponents()
  // const { data: experiment, isLoading } = useQuery({
  //   queryKey: [ 'experimentById', experimentKey ],
  //   queryFn: () => wizataApi.experiments.getById(experimentKey),
  //   enabled: !!experimentKey,
  //   staleTime: Infinity,
  // })

  return (
    <>
      <FormField
        label={intl.t('form.fields.name')}
        description={intl.t('components.form.fields.nameDescr')}
        errors={get(errors, `${scope}.name`)}
      >
        <FormControl
          name={`${scope}.name`}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
          }}
        />
      </FormField>
      <FormField
        label={intl.t('form.fields.template')}
        description={intl.t('components.form.fields.templateDescr')}
        errors={get(errors, `${scope}.templateId`)}
      >
        <Controller
          name={`${scope}.templateId`}
          render={({ field, fieldState }) => (
            <FormTemplates
              {...field}
              invalid={fieldState.invalid}
              disabled={!auth.checkAccessUpdate('Template')}
              placeholder="--"
            />
          )}
        />
      </FormField>
      <FormField
        label={intl.t('form.fields.label')}
        description={intl.t('components.form.fields.labelDescr')}
        errors={get(errors, `${scope}.labelId`)}
      >
        <Controller
          name={`${scope}.labelId`}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
          }}
          render={({ field, fieldState }) => (
            <ListBusinessLabels
              {...field}
              invalid={fieldState.invalid}
              placeholder="--"
            />
          )}
        />
      </FormField>
      {isGrafana ? (
        <>
          <FormField
            label={intl.t('form.fields.defaultFrom')}
            description={intl.t('components.form.fields.defaultFromDescr')}
            errors={get(errors, `${scope}.defaultFrom`)}
          >
            <FormControl
              name={`${scope}.defaultFrom`}
              rules={{
                pattern: DEFAULT_VALUE_REGEXP,
              }}
            />
          </FormField>
          <FormField
            label={intl.t('form.fields.defaultTo')}
            description={intl.t('components.form.fields.defaultToDescr')}
            errors={get(errors, `${scope}.defaultTo`)}
          >
            <FormControl
              name={`${scope}.defaultTo`}
              rules={{
                pattern: DEFAULT_VALUE_REGEXP,
              }}
            />
          </FormField>
          <FormField
            label={intl.t('form.fields.grafana')}
            description={intl.t('components.form.fields.grafanaDescr')}
            errors={get(errors, `${scope}.content`)}
          >
            <FormControl
              name={`${scope}.content`}
            />
          </FormField>
          <FormField
            label={intl.t('form.fields.refreshInterval')}
            description={intl.t('form.fields.refreshIntervalDescr')}
            errors={get(errors, `${scope}.refreshInterval`)}
          >
            <Controller
              name={`${scope}.refreshInterval`}
              render={({ field: { onChange, value, ...field }, fieldState }) => (
                <FormSelectNative
                  {...field}
                  invalid={fieldState.invalid}
                  value={value}
                  placeholder="--"
                  options={consts.GrafanaStepRequests}
                  onChange={(refreshInterval) => {
                    onChange(Number(refreshInterval || 0))
                  }}
                />
              )}
            />
          </FormField>
        </>
      ) : null}
      {isIframe ? (
        <FormField
          label={intl.t('form.fields.iframe')}
          description={intl.t('components.form.fields.iframeDescr')}
          errors={get(errors, `${scope}.content`)}
        >
          <FormControl
            name={`${scope}.content`}
            rules={{
              pattern: {
                value: URL_REGEXP,
                message: intl.t('form.errors.fieldURL'),
              },
            }}
          />
        </FormField>
      ) : null}
      {isStreamlit ? (
        <FormField
          label={intl.t('form.fields.streamlit')}
          description={intl.t('components.form.fields.StreamlitDescr')}
          errors={get(errors, `${scope}.content`)}
        >
          <FormControl
            name={`${scope}.content`}
            rules={{
              pattern: {
                value: URL_REGEXP,
                message: intl.t('form.errors.fieldURL'),
              },
            }}
          />
        </FormField>
      ) : null}
      {isOrganizational ? (
        <FormFieldInline
          label={intl.t('form.fields.organizational')}
          description={intl.t('form.fields.organizationalDescr')}
          errors={get(errors, `${scope}.isOrganizational`)}
        >
          <Controller
            shouldUnregister
            name={`${scope}.isOrganizational`}
            render={({ field, fieldState }) => (
              <FormCheckbox
                {...field}
                invalid={fieldState.invalid}
                className="ms-2"
                disabled={!isOrganizational}
              />
            )}
          />
        </FormFieldInline>
      ) : null}
    </>
  )
}

Fields.propTypes = {
  scope: PropTypes.string.isRequired,
  isIframe: PropTypes.bool,
}

Fields.defaultProps = {
  isIframe: false,
}
