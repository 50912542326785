import {
  useMemo, useCallback, useRef,
} from 'react'
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query'
import { dbProvider, Dashboard } from '@wiz/store'
import events from '@/utils/events'
import { wizataApi } from '@/api'
import { useAuth } from '@/auth'
import { useLocationQuery, useRouter, useLocation } from '@/router'
import useAuthComponents from '@/pages/operate/hooks/useAuthComponents'
import FormDialog from '@/components/Form/FormDialog'
import { PagesMap } from '@/utils/consts'
import SectionInsights from '@/components/Forms/Twin/SectionInsights'
import { useGlobalExecute } from '@/context/GlobalExecuteProvider'
import Section from './Section'

const GrafanaDashboards = ({
  dialog,
  onClose,
  onUpdate,
  items,
  onSuccess,
  scope,
  item,
  insights = [],
  withInsights,
  forceInsightsFetch,
}) => {
  const ref = useRef()
  const { contextTwinId } = useGlobalExecute()
  const auth = useAuth()
  const queryClient = useQueryClient()
  const { twinId, category } = useLocationQuery()
  const router = useRouter()
  const loc = useLocation()
  const { isPersonalStrictly, checkAvailability } = useAuthComponents()
  const [ ,,, currentPage ] = loc.pathname.split('/')

  const {
    mutateAsync: createDashboardGrafana,
  } = useMutation({
    mutationKey: [ 'createDashboardGrafana' ],
    mutationFn: data => wizataApi.grafana.createDashboardGrafana(data),
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/components.create.error',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess,
  })

  const {
    mutateAsync: createDashboardNative,
  } = useMutation({
    mutationKey: [ 'createDashboardNative' ],
    mutationFn: data => wizataApi.dashboardsComponents.createDashboardNative(data),
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/components.create.error',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess,
  })

  const {
    mutateAsync: updateComponent,
  } = useMutation({
    mutationKey: [ 'updateComponent', item?.id ],
    mutationFn: data => wizataApi.dashboardsComponents.update(data),
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/components.update.error',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: (res) => {
      if (![ 'streamlit', 'iframe' ].includes(scope)) {
        onSuccess(res)
      } else {
        queryClient.invalidateQueries({ queryKey: [ 'currentTwinDashboards', contextTwinId ] })
        queryClient.invalidateQueries({ queryKey: [ 'dashboardsComponentsDeepList', contextTwinId ] })
      }
    },
  })

  const {
    mutateAsync: mutateInsight,
  } = useMutation({
    mutationKey: [ 'mutateInsight' ],
    mutationFn: (data) => {
      if (data.id) {
        return wizataApi.insights.update(data)
      }
      return wizataApi.insights.create(data)
    },
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/insights.update.error',
        message: err.message,
        duration: 5000,
      })
    },
  })

  const {
    data: fetchedInsights,
  } = useQuery({
    queryKey: [ 'singleComponentInsights', item?.id ],
    queryFn: () => wizataApi.insights.getListByComponents([ item.id ]),
    enabled: !!item && !!forceInsightsFetch && !!withInsights,
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess: (res) => {
      ref.current?.refreshInsights?.(res)
    },
  })

  const replaceInsight = useCallback(async (component, insightData) => {
    const next = {
      name: insightData.name,
      displayPrecision: insightData.displayPrecision,
      sensorId: insightData.sensorId,
      condition: {},
      componentId: component.id,
    }
    if (insightData.id && !insightData.id.startsWith('new')) {
      next.id = insightData.id
    }
    if (insightData.condition?.parsed?.length) {
      next.condition.parsed = insightData.condition.parsed
      next.condition.value = ''
    }
    next.condition = JSON.stringify(next.condition)

    const response = await mutateInsight(next)

    return response
  }, [ mutateInsight ])

  const handleCreateDashboard = useCallback(async (data) => {
    const context = dbProvider.createBatchContext()
    const model = await dbProvider.prepareReplaceData(context, Dashboard, data)
    if (auth.checkAccessRead('Twin')) {
      const twins = data.twinId ? [ data.twinId ] : []
      await model.prepareReplaceTwins(context, twins)
    }
    await dbProvider.batch(context)

    // if (auth.checkAccessShare(model)) {
    //   context = dbProvider.createBatchContext()
    //   await model.prepareReplaceShareUsers(context, data.permissions)
    //   await dbProvider.batch(context)
    // }
    return model
  }, [ auth ])

  const handleUpdate = useCallback(async (data) => {
    let result
    if (data.type === 'Dashboard') {
      result = await handleCreateDashboard({ ...data, id: data.dashboardId, title: data.name })
      const model = { ...Dashboard.toJSON(result) }
      if (!data.isOrganizational) {
        model.ownerId = auth.currentUser.id

        if (currentPage === PagesMap.Components && data?.ownerId) {
          model.ownerId = data?.ownerId
        }
      }
      result = await updateComponent({
        ...model,
        id: data.componentId || item.id,
        labelId: data.labelId,
        twinId: data.twinId,
        name: model.title,
        dashboardId: model.id,
        type: data.type,
        order: data.order,
      })
    } else {
      result = await updateComponent({ ...data, id: item.id })
    }
  }, [ auth, handleCreateDashboard, updateComponent, item, currentPage ])

  const handleSyncSuccess = useCallback(() => {
    dbProvider.sync.removeListener('synced', handleSyncSuccess)
  }, [])

  const handleSubmit = async ({ component, insights: _insights }) => {
    let result

    const next = { ...component, ownerId: component?.ownerId ? component.ownerId : auth.currentUser.id }
    delete next.id

    if (component.isOrganizational) {
      delete next.ownerId
    }

    if (item) {
      _insights?.list?.forEach((insight, idx) => {
        replaceInsight(component, insight).then(() => {
          if (idx === _insights.list.length - 1) {
            queryClient.invalidateQueries({ queryKey: [ 'insightsListByComponents', contextTwinId ] })
          }
        })
      })
      result = await handleUpdate(next)
      return result
    }

    if (contextTwinId) {
      next.twinId = contextTwinId
    }

    if (scope === 'dashboard') {
      next.type = 'Dashboard'
      result = await createDashboardNative(next)
    } else if (scope === 'grafana') {
      result = await createDashboardGrafana(next)
    } else if (scope === 'iframe') {
      result = await onSuccess(next) // onSuccess is for direct create component
    } else if (scope === 'streamlit') {
      result = await onSuccess(next)
    }

    if (_insights?.list.length) {
      Promise.all(_insights.list.map(insight => replaceInsight(component, insight))).finally(() => {
        queryClient.invalidateQueries({ queryKey: [ 'insightsListByComponents', contextTwinId ] })
        if (item) {
          queryClient.invalidateQueries({ queryKey: [ 'singleComponentInsights', item?.id ] })
        }
      })
    }

    return result
  }

  const handleSuccess = async (data) => {
    await onUpdate?.()
    queryClient.invalidateQueries({ queryKey: [ 'insightsListByComponents', contextTwinId ] })

    if (data) {
      router.replace({
        query: {
          category: data.labelId || category,
          sub: data.id,
          defaultFrom: data.defaultFrom,
          defaultTo: data.defaultTo,
          exact: true,
        },
      })
    }
  }

  const defaultValues = useMemo(() => {
    let insightsList = []

    if (insights.length) {
      insightsList = insights
    } else if (fetchedInsights?.length) {
      insightsList = fetchedInsights
    }

    return {
      component: {
        labelId: category && category !== 'search' ? category : undefined,
        twinId,
        order: item?.order || (items?.length ? (items[items.length - 1]?.order || 0) + 1 : 1),
        isOrganizational: isPersonalStrictly ? false : !item?.ownerId,
        ...item,
      },
      insights: withInsights ? {
        list: [
          ...insightsList.map(insight => ({ ...insight, condition: JSON.parse(insight.condition) })),
        ],
        selected: null,
      } : undefined,
    }
  }, [ item, items, category, twinId, isPersonalStrictly, insights, withInsights, fetchedInsights ])

  return (
    <FormDialog
      onClose={onClose}
      onSubmit={handleSubmit}
      onSuccess={handleSuccess}
      defaultValues={defaultValues}
      dataTestid="dashboardGrafanaCreateForm"
      dialog={dialog}
    >
      <Section
        scope="component"
        isIframe={scope === 'iframe'}
        isGrafana={scope === 'grafana' || item?.type?.toLowerCase() === 'grafana'}
        isStreamlit={scope === 'streamlit'}
      />
      {withInsights &&
      item &&
      checkAvailability(item.ownerId === auth.currentUser.id) ?
        <SectionInsights scope="insights" ref={ref} /> : null}
    </FormDialog>
  )
}

export default GrafanaDashboards
