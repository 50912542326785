export default function settings (client) {
  return function decorator (target) {
    Object.defineProperties(target.prototype, {
      settingsPipelines: {
        enumerable: true,
        value: Object.create({}, {
          uploadCustom: {
            enumerable: true,
            value (params) {
              return client.put('/CustomConfigurationFiles', params, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              }).fetch()
            },
          },
          getCustom: {
            enumerable: true,
            value () {
              return client.get('/CustomConfigurationFiles').fetch(true)
            },
          },
          uploadRequirements: {
            enumerable: true,
            value (params) {
              return client.put('/CustomRequirementsFiles', { ...params }).fetch()
            },
          },
          getRequirements: {
            enumerable: true,
            value () {
              return client.get('/CustomRequirementsFiles').fetch(true)
            },
          },
        }),
      },
      licenseManagement: {
        enumerable: true,
        value: Object.create({}, {
          getFeatures: {
            enumerable: true,
            value () {
              return client.get('/Features').fetch()
            },
          },
          sendRequest: {
            enumerable: true,
            value (params) {
              return client.post('/Features/request', { ...params }).fetch()
            },
          },
        }),
      },
    })
  }
}
