import {
  useState, useMemo, useRef, useCallback, useEffect,
} from 'react'
import classnames from 'classnames'
import { useRouter, useLocationQuery, useLocation } from '@/router'
import { useAuth, auth } from '@/auth'
import { useGlobalExecute } from '@/context/GlobalExecuteProvider'
import { useIntl } from '@wiz/intl'
import { DropdownMenu } from '@wiz/components'
import Icon from '@/shared/icon'
import useAuthComponents from '@/pages/operate/hooks/useAuthComponents'
import classes from './index.module.css'

const SingleItem = ({
  item, index, onDragEnd, onDrop, onDragStart, onClick, onAction, isLoading, isEdit, options,
}) => {
  const [ isActive, setActive ] = useState(false)
  const refMenu = useRef()
  const refMenuTarget = useRef()

  const intl = useIntl()
  const router = useRouter()
  const query = useLocationQuery()
  const { currentUser } = useAuth()
  const { isReadOnly, checkAvailability } = useAuthComponents()
  const { selectedComponent, setSelectedComponent } = useGlobalExecute()

  const handleClick = useCallback(() => {
    setSelectedComponent({ ...selectedComponent, dashboard: item })
    setActive(true)
    setTimeout(() => onClick(item), 100)
  }, [ onClick, setActive, item, selectedComponent, setSelectedComponent ])

  // useEffect(() => {
  //   if (query.sub) {
  //     const isSelectedComponent = selectedComponent?.dashboard?.name &&
  //     item.name === selectedComponent?.dashboard?.name
  //     if (isSelectedComponent) {
  //       router.push({
  //         query: { ...query, sub: item.id },
  //       })
  //     }
  //   }
  // }, [ selectedComponent, query, router, options ])

  // useEffect(() => {
  //   // console.log('-------')
  //   // console.log('query.sub: ', query.sub)
  //   // console.log('query.category: ', query.category)
  //   if (query.sub && !!query.category && query.category !== 'search') {
  //     const isSelectedComponent = selectedComponent?.dashboard?.name &&
  //       item.name === selectedComponent?.dashboard?.name
  //     const optionIds = options.map(opt => opt.id)
  //     console.log('selectedComponent:', selectedComponent)
  //     if (!isSelectedComponent) {
  //       console.log('/?/')
  //       router.push({
  //         query: {
  //           ...query,
  //           sub: !optionIds.includes(selectedComponent?.dashboard?.id) ?
  //             optionIds[0] :
  //             selectedComponent?.dashboard?.id,
  //         },
  //       })
  //     }
  //   }
  // }, [ selectedComponent, query.sub ])

  // const title = item.name || item.label
  const isPersonalComponent = currentUser.id === item.ownerId
  const isCurrentTab = item.id === query.sub

  const isAvailable = checkAvailability(isPersonalComponent)

  const dashboardActions = [
    {
      value: 'newTab',
      name: 'component.actions.openNewTab',
      id: 'newTab',
      access: true,
    },
    {
      value: 'edit',
      name: 'component.actions.edit',
      id: 'edit',
      access: isAvailable,
    },
    {
      value: 'editProperties',
      name: 'component.actions.editProperties',
      id: 'editProperties',
      access: isAvailable,
    },
    {
      value: 'duplicate',
      name: 'component.actions.duplicate',
      id: 'duplicate',
      access: !isReadOnly,
    },
    {
      value: 'remove',
      name: 'component.actions.remove',
      id: 'remove',
      access: isAvailable,
    },
  ].filter(item => !!item.access)

  useEffect(() => {
    if (isCurrentTab && isActive) {
      setActive(false)
    }
  }, [ query.sub ])

  useEffect(() => {
    if (!isActive && isCurrentTab) {
      setActive(true)
    }
  }, [])

  return (
    <div
      key={item.id}
      className="position-relative"
    >
      <button
        ref={refMenuTarget}
        type="button"
        className={classnames(
          'btn btn-text fs-7 outline-none',
          classes.tabBtn,
          { [classes.active]: isCurrentTab },
        )}
        disabled={isLoading}
        onClick={handleClick}
        {...(isAvailable && (isLoading && isCurrentTab) ? {
          draggable: true,
          onDragStart: e => onDragStart(e, item, index),
          onDragEnter: e => onDragEnd(e, item, index),
          onDragEnd: onDrop,
        } :
          undefined
        )}
      >
        {item.label}
        {isCurrentTab && dashboardActions.length ? (
          <Icon
            type="solid"
            name={isLoading ? 'faSpinner' : 'faCaretDown'}
            className="ms-1"
            spin={isLoading}
          />
        ) : null}
      </button>
      {isPersonalComponent ? (
        <Icon
          type="solid"
          className="position-absolute end-0 bottom-0 mb-1 me-2"
          name="faUser"
          size="xs"
          title="Personal"
        />
      ) : null}
      {dashboardActions.length && isCurrentTab ? (
        <DropdownMenu
          ref={refMenu}
          target={refMenuTarget}
          arrow
          placement="bottom-start"
          draggable
          autoclose
          options={dashboardActions.map((type) => {
            const next = { ...type, name: intl.t(type.name) }
            if (type === 'Iframe') {
              next.disabled = true
              return next
            }
            if (type.id === 'edit') {
              next.disabled = isEdit
            }
            return next
          })}
          onClick={({ id }) => onAction({ ...item, componentId: item.id, action: id })}
        />
      ) : null}
    </div>
  )
}

export default function Breadcrumb ({
  options,
  children,
  onDelete,
  onDuplicate,
  onEditing,
  onComponentEdit,
  editId,
  ...props
}) {
  const router = useRouter()
  const location = useLocation()
  const query = useLocationQuery()
  const { isReadOnly, isPersonalStrictly } = useAuthComponents()

  const handleClick = async ({
    id, defaultFrom, defaultTo,
  }) => {
    if (editId) {
      onComponentEdit(null)
    }
    if (id !== query.sub) {
      if (props.isEdit && id !== query.sub) {
        await window.wizConfirm({ message: 't/components.leaveEdit' })
        onEditing(false)
      }
      router.push({
        query: {
          ...query, sub: id, defaultFrom, defaultTo,
        },
      })
    }
  }

  const handleAction = (data) => {
    const {
      action, componentId, labelId, name, type, twinId, content, dashboardId, templateId, value, defaultFrom, defaultTo,
    } = data

    if (action === 'newTab') {
      const { origin } = window.location

      window.open(
        `${origin}${location.pathname}?category=${labelId}&contextTwinId=${twinId}&sub=${dashboardId}`,
        '_blank',
      )
    }
    if (isReadOnly) {
      return
    }

    if (action === 'remove') {
      onDelete(componentId)
    } else if (action === 'duplicate') {
      const [ , sourceUid ] = content?.split('/') || ''
      onDuplicate({
        labelId,
        order: options?.length ? options[options.length - 1].order + 1 : 1,
        sourceUid,
        twinId,
        name: `Copy of ${name}`,
        title: name,
        content,
        type,
        dashboardId,
        templateId,
        value,
        componentId,
        defaultFrom,
        defaultTo,
      })
    } else if (action === 'edit') {
      onEditing(true)
    } else if (action === 'editProperties') {
      onComponentEdit(data)
    }
  }

  return (
    <nav aria-label="breadcrumb" className="d-flex flex-wrap flex-fill">
      {/* <div className="d-flex flex-wrap flex-fill"> */}
      {options.map((item, index) => (
        <SingleItem
          options={options}
          key={item.id}
          {...props}
          item={item}
          index={index}
          onClick={handleClick}
          onAction={handleAction}
        />
      ))}
      {/* </div> */}
      {children}
    </nav>
  )
}
