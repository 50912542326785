import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react'
import cx from 'classnames'
import { useIntl } from '@wiz/intl'
import {
  Popup, Icon as LegacyIcon, CustomScrollbars, TableWidget,
} from '@wiz/components'
import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query'
import events from '@/utils/events'
import { wizataApi } from '@/api'
import { useRouter } from '@/router'
import FormEdgeFunction from '@/components/Forms/EdgeDevices/Functions'
import FormEdgeConsumer from '@/components/Forms/EdgeDevices/Consumers'
import FormEdgeWriter from '@/components/Forms/EdgeDevices/Writers'
import FormEdgeTrigger from '@/components/Forms/EdgeDevices/Triggers'
import Loading from '@/shared/loading'
import Icon from '@/shared/icon'
import LicensePopup from '@/shared/licensePopup'
import {
  EdgeDeviceStatus, EdgeDeviceStatusColorsScheme, EdgeDevicesEntity, RunnerConnectionType,
} from '@/utils/consts'
import useLicense from '@/pages/operate/hooks/useLicense'
import { useGlobalExecute } from '@/context/GlobalExecuteProvider'
import { ModulesTypes } from './utils'
import EdgeLogs from './Logs'
import classes from './index.module.css'

export default function View ({ match: { params: { id } } }) {
  const [ editItem, setEditItem ] = useState()
  const [ deletingItem, setDeletingItem ] = useState()
  const [ isPopupOpened, setIsPopupOpened ] = useState(false)
  const [ isLicensePopupOpen, setLicensePopupOpen ] = useState(false)
  const [ timestamp, setTimestamp ] = useState({
    from: Date.now() - 60 * 60 * 1000,
    to: Date.now(),
  })

  const refDialog = useRef()
  const refLicenseDialog = useRef()

  const { isProLicense } = useLicense()
  const { handleTwinChange } = useGlobalExecute()

  const queryClient = useQueryClient()
  const intl = useIntl()
  const router = useRouter()

  const { data: item, isLoading } = useQuery({
    queryKey: [ 'singleEdgeDevice', id ],
    queryFn: () => wizataApi.edgeDevices.getById(id),
    enabled: !!id,
    retry: false,
    refetchOnWindowFocus: false,
  })

  const {
    mutateAsync: deployModules, isLoading: isDeploying,
  } = useMutation({
    mutationKey: [ 'deployEdgeDeviceModules', id ],
    mutationFn: () => wizataApi.edgeDevices.deployModules(id),
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/edgeDevices.errors.deployModules',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: (res) => {
      // queryClient.invalidateQueries({ queryKey: [ 'edgeDevicesFunctions', id ] })
    },
  })

  const isDeployed = !!item?.deviceType && item?.status !== EdgeDeviceStatus.WaitingForRegistration

  const {
    data: modules, isLoading: isModulesLoading, isFetching: isModulesFetching,
  } = useQuery({
    queryKey: [ 'edgeDeviceModules', id ],
    queryFn: () => wizataApi.edgeDevices.getModules(id),
    enabled: !!item && !!id && isDeployed,
    retry: false,
    refetchOnWindowFocus: false,
    onSettled: (res) => {
      if (logs?.fetchStatus !== 'fetching') {
        setTimeout(() => { logs?.refetch() }, 0)
      }
    },
  })

  const logs = useQuery({
    queryKey: [ 'singleEdgeDeviceLogs', id ],
    queryFn: () => wizataApi.edgeDevices.getLogs({
      from: new Date(timestamp.from),
      to: new Date(timestamp.to),
      edgeDeviceId: item.deviceId, // 'wizata-dev-edge'
      limit: 0,
      offset: 0,
      appIds: modules?.map(module => ModulesTypes[module.moduleId]).filter(Boolean),
    }),
    enabled: false,
    retry: false,
    refetchOnWindowFocus: false,
  })

  const module = useMutation({
    mutationKey: [ 'moduleRestart', id ],
    mutationFn: moduleId => wizataApi.edgeDevices.refreshModule(id, moduleId),
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/edgeDevices.errors.moduleRestart',
        message: err.meesage,
        duration: 10000,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries([ 'edgeDeviceModules', id ])
    },
  })

  const { data: consumers, isLoading: isConsumersLoading } = useQuery({
    queryKey: [ 'edgeDeviceConsumers', id ],
    queryFn: () => wizataApi.edgeDevices.getConfigs(id, EdgeDevicesEntity.consumers),
    enabled: !!id && isDeployed,
    retry: false,
    refetchOnWindowFocus: false,
  })

  const { data: writers, isLoading: isWritersLoading } = useQuery({
    queryKey: [ 'edgeDeviceWriters', id ],
    queryFn: () => wizataApi.edgeDevices.getConfigs(id, EdgeDevicesEntity.writers),
    enabled: !!id && isDeployed,
    retry: false,
    refetchOnWindowFocus: false,
  })

  const { data: triggers, isLoading: isTriggersLoading } = useQuery({
    queryKey: [ 'edgeDeviceTriggers', id ],
    queryFn: () => wizataApi.edgeDevices.getConfigs(id, EdgeDevicesEntity.triggers),
    enabled: !!id && isDeployed,
    retry: false,
    refetchOnWindowFocus: false,
  })

  const { data: functions, isLoading: isFunctionsLoading } = useQuery({
    queryKey: [ 'edgeDeviceFunctions', id ],
    queryFn: () => wizataApi.edgeDevices.getFunctions(id),
    enabled: !!id && isDeployed,
    retry: false,
    refetchOnWindowFocus: false,
  })

  const {
    mutateAsync: updateEdgeDevice, isLoading: isUpdating,
  } = useMutation({
    mutationKey: [ 'updateEdgeDevice' ],
    mutationFn: deviceType => wizataApi.edgeDevices.update(id, { deviceType }),
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/edgeDevices.errors.update',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: (res) => {
      deployModules()
    },
  })

  const {
    mutateAsync: deleteEdgeDeviceConfigs, isLoading: isDeleting,
  } = useMutation({
    mutationKey: [ 'deleteEdgeDeviceConfigs' ],
    mutationFn: data => wizataApi.edgeDevices.deleteConfigs(id, data?.configType, data?.id),
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/edgeDevices.errors.delete',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: () => {
      const capitalizedKey = String(deletingItem?.configType).charAt(0).toUpperCase() +
        String(deletingItem?.configType).slice(1)
      queryClient.invalidateQueries({
        queryKey:
        [ `edgeDevices${capitalizedKey}`, id ],
      })
    },
  })

  const {
    mutateAsync: deleteEdgeDeviceFunction, isLoading: isDeletingFunction,
  } = useMutation({
    mutationKey: [ 'deleteEdgeDeviceFunction' ],
    mutationFn: data => wizataApi.edgeDevices.deleteFunction(id, data?.id),
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/edgeDevices.errors.delete',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ 'edgeDevicesFunctions', id ] })
    },
  })

  const handleDeleteItem = useCallback(async (data) => {
    await window.wizConfirm({ message: 't/edgeDeviceItem.confirmDelete' })
    setDeletingItem(data)
    if (data.configType === EdgeDevicesEntity.functions) {
      await deleteEdgeDeviceFunction(data)
    } else {
      await deleteEdgeDeviceConfigs(data)
    }
  }, [ deleteEdgeDeviceFunction, deleteEdgeDeviceConfigs ])

  const handleClickDeviceType = async (type) => {
    await updateEdgeDevice(type)
  }

  const isReadOnly = !isProLicense // do more checks based on permissions

  const columns = useMemo(() => [
    {
      Header: intl.t('form.fields.name'),
      customHeaderStyles: {
        backgroundColor: '#031E37',
      },
      accessor: 'moduleId',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 300,
      Cell: ({ cell }) => (cell.value ? (
        <div className="min-w-0 text-truncate">
          {cell.value}
        </div>
      ) : '-'),
    },
    {
      Header: intl.t('form.fields.version'),
      customHeaderStyles: {
        backgroundColor: '#031E37',
      },
      accessor: 'version',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 30,
      Cell: ({ cell }) => (cell.value ? (
        <div className="min-w-0 text-truncate">
          {cell.value}
        </div>
      ) : '-'),
    },
    {
      Header: intl.t('form.fields.runtimeStatus'),
      customHeaderStyles: {
        backgroundColor: '#031E37',
      },
      accessor: 'status',
      minWidth: 300,
      Cell: ({ cell }) => (
        <span className={cx(classes.edgeModuleStatus, classes[cell.value])}>{cell.value}</span>
      ),
    },
    {
      // Header: intl.t('form.fields.updated'),
      customHeaderStyles: {
        backgroundColor: '#031E37',
      },
      accessor: 'fakeId',
      disableResizing: true,
      minWidth: 300,
      Cell: ({ row }) => (isReadOnly ? null : (
        <button
          type="button"
          className="btn btn-secondary border-0"
          style={{ fontSize: '12px' }}
          disabled={isReadOnly}
          onClick={() => module.mutateAsync(row.original.moduleId)}
        >
          <Icon name="faArrowsRotate" className="me-2" />
          {intl.t('form.actions.restart')}
        </button>
      )),
    },
    // {
    //   Header: intl.t('form.fields.updated'),
    //   customHeaderStyles: {
    //     backgroundColor: '#031E37',
    //   },
    //   accessor: 'updatedDate',
    //   disableResizing: true,
    //   minWidth: 300,
    //   Cell: () => (
    //     <button
    //       type="button"
    //       className="btn btn-secondary border-0"
    //       style={{ fontSize: '12px' }}
    //       onClick={() => console.log('daadsa')}
    //     >
    //       <Icon name="faArrowsRotate" className="me-2" />
    //       {intl.t('form.actions.refresh')}
    //     </button>
    //   ),
    // },
    // {
    //   customHeaderStyles: {
    //     backgroundColor: '#031E37',
    //   },
    //   accessor: 'fake',
    //   disableResizing: true,
    //   disableSortBy: true,
    //   minWidth: 40,
    //   maxWidth: 40,
    //   width: 40,
    //   Cell: () => (
    //     <button className={cx(classes.btnDelete)} type="button" onClick={handleDeleteItem}>
    //       <Icon name="faTrashCan" />
    //     </button>
    //   ),
    // },
  ], [ intl, module, isReadOnly ])

  const functionColumns = useMemo(() => [
    {
      Header: intl.t('form.fields.name'),
      customHeaderStyles: {
        backgroundColor: '#031E37',
      },
      accessor: name => name,
      disableResizing: true,
      disableSortBy: true,
      minWidth: 200,
      Cell: ({ cell, row }) => (cell.value ? (
        <div className="min-w-0 text-truncate">
          <a
            className="text-truncate pointer"
            onClick={() => setEditItem({ component: EdgeDevicesEntity.functions, id: cell.value })}
            aria-hidden
          >
            {cell.value}
          </a>
        </div>
      ) : '-'),
    },
    {
      customHeaderStyles: {
        backgroundColor: '#031E37',
      },
      accessor: 'fake',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 40,
      maxWidth: 40,
      width: 40,
      Cell: ({ cell, row }) => (isReadOnly ? null : (
        <button
          className={cx(classes.btnDelete)}
          type="button"
          disabled={isReadOnly}
          onClick={() => handleDeleteItem({
            id: row?.original.split('.')[0],
            configType: EdgeDevicesEntity.functions,
          })}
        >
          <Icon name="faTrashCan" />
        </button>
      )),
    },
  ], [ intl, handleDeleteItem, isReadOnly ])

  const consumerColumns = useMemo(() => [
    {
      Header: intl.t('form.fields.id'),
      customHeaderStyles: {
        backgroundColor: '#031E37',
      },
      accessor: 'id',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 150,
      Cell: ({ cell, row }) => (cell.value ? (
        <a
          className="text-truncate pointer p-2"
          onClick={() => setEditItem({ component: EdgeDevicesEntity.consumers, id: row.original.id })}
          aria-hidden
        >
          {cell.value}
        </a>
      ) : '-'),
    },
    {
      Header: intl.t('form.fields.type'),
      customHeaderStyles: {
        backgroundColor: '#031E37',
      },
      accessor: 'type',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 100,
      maxWidth: 100,
      width: 100,
      Cell: ({ cell, row }) => (cell.value ? (
        <div className="min-w-0 text-truncate">
          {cell.value}
        </div>
      ) : '-'),
    },
    {
      customHeaderStyles: {
        backgroundColor: '#031E37',
      },
      accessor: 'fake',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 40,
      maxWidth: 40,
      width: 40,
      Cell: ({ cell, row }) => (isReadOnly ? null : (
        <button
          className={cx(classes.btnDelete)}
          type="button"
          disabled={isReadOnly}
          onClick={() => handleDeleteItem({
            id: row?.original?.id,
            configType: EdgeDevicesEntity.consumers,
          })}
        >
          <Icon name="faTrashCan" />
        </button>
      )),
    },
  ], [ intl, handleDeleteItem, isReadOnly ])

  const writerColumns = useMemo(() => [
    {
      Header: intl.t('form.fields.id'),
      customHeaderStyles: {
        backgroundColor: '#031E37',
      },
      accessor: 'id',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 150,
      Cell: ({ cell, row }) => (cell.value ? (
        <a
          className="text-truncate pointer p-2"
          onClick={() => setEditItem({ component: EdgeDevicesEntity.writers, id: row.original.id })}
          aria-hidden
        >
          {cell.value}
        </a>
      ) : '-'),
    },
    {
      Header: intl.t('form.fields.type'),
      customHeaderStyles: {
        backgroundColor: '#031E37',
      },
      accessor: 'type',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 100,
      maxWidth: 100,
      width: 100,
      Cell: ({ cell, row }) => (cell.value ? (
        <div className="min-w-0 text-truncate">
          {cell.value}
        </div>
      ) : '-'),
    },
    {
      customHeaderStyles: {
        backgroundColor: '#031E37',
      },
      accessor: 'fake',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 40,
      maxWidth: 40,
      width: 40,
      Cell: ({ cell, row }) => (isReadOnly ? null : (
        <button
          className={cx(classes.btnDelete)}
          type="button"
          disabled={isReadOnly}
          onClick={() => handleDeleteItem({
            id: row?.original?.id,
            configType: EdgeDevicesEntity.writers,
          })}
        >
          <Icon name="faTrashCan" />
        </button>
      )),
    },
  ], [ intl, handleDeleteItem, isReadOnly ])

  const triggerColumns = useMemo(() => [
    {
      Header: intl.t('form.fields.id'),
      customHeaderStyles: {
        backgroundColor: '#031E37',
      },
      accessor: 'id',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 150,
      Cell: ({ cell, row }) => (cell.value ? (
        <a
          className="text-truncate pointer p-2"
          onClick={() => setEditItem({ component: EdgeDevicesEntity.triggers, id: row.original.id })}
          aria-hidden
        >
          {cell.value}
        </a>
      ) : '-'),
    },
    {
      customHeaderStyles: {
        backgroundColor: '#031E37',
      },
      accessor: 'fake',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 40,
      maxWidth: 40,
      width: 40,
      Cell: ({ cell, row }) => (isReadOnly ? null : (
        <button
          className={cx(classes.btnDelete)}
          type="button"
          disabled={isReadOnly}
          onClick={() => handleDeleteItem({
            id: row?.original?.id,
            configType: EdgeDevicesEntity.triggers,
          })}
        >
          <Icon name="faTrashCan" />
        </button>
      )),
    },
  ], [ intl, handleDeleteItem, isReadOnly ])

  const deployModulesBtn = useMemo(() => {
    if (isDeploying || (isModulesLoading && isModulesFetching)) {
      return <Loading />
    }
    if (!item?.deviceType) {
      return (
        <button
          className="btn btn-secondary border-0 my-3"
          type="button"
          disabled={isReadOnly}
          onClick={() => setIsPopupOpened(true)}
        >
          {intl.t('edgeDevices.actions.deployModules')}
        </button>
      )
    }
    return ''
  }, [ item, isDeploying, intl, isModulesLoading, isModulesFetching, isReadOnly ])

  const handleTwinOpen = () => {
    handleTwinChange(item.twinId, true)
    router.push({ name: 'twin-items-list', query: { tree: item.twinId } })
  }

  const handleTimestampChange = useCallback((data) => {
    setTimestamp(data)
  }, [])

  const handleLicense = () => {
    if (isProLicense) {
      handleClickDeviceType(RunnerConnectionType.ConnectivityAndPipelines)
    } else {
      setLicensePopupOpen(true)
    }
  }

  useEffect(() => {
    if (timestamp.from && timestamp.to && logs?.data && !logs?.isLoading) {
      logs.refetch()
    }
  }, [ timestamp.from ])

  if (isLoading) {
    return (
      <div className="position-absolute-fill position-center-fill bg-light-alt opacity-50">
        <Icon name="faSpinner" spin />
      </div>
    )
  }

  // const isRegistrationStatusLoading = registrationStatus.isLoading && registrationStatus.isFetching

  return (
    <CustomScrollbars>
      <div className={cx(classes.edgeViewWrapper, '')}>
        <div className={cx(classes.tilesRow, '')}>
          <div className={cx(classes.tile, '')}>
            <div className={cx(classes.statusesBlock, 'text-truncate')}>
              <h6>{intl.t('edgeDevices.view.deviceId')}</h6>
              <span>{item.deviceId}</span>
            </div>
          </div>

          <div className={cx(classes.tile, 'position-relative')}>
            {/* {isRegistrationStatusLoading ? <Loading /> : null} */}
            <div
              className={cx(classes.statusesBlock, 'text-truncate')}
              style={
              {
                backgroundColor: EdgeDeviceStatusColorsScheme[item.status],
                borderColor: EdgeDeviceStatusColorsScheme[item.status],
                color: '#01101d',
              }
            }
            >
              <h6>{intl.t('edgeDevices.view.status')}</h6>
              <span>{intl.t(`edgeDevices.status.${item.status}`)}</span>
              <span>
&nbsp;(
                {item.statusCode}
                )
              </span>
            </div>
          </div>

          <div className={cx(classes.tile, '')}>
            <div
              className={cx(classes.statusesBlock, classes.link, 'text-truncate')}
              onClick={handleTwinOpen}
              aria-hidden
            >
              <h6>{intl.t('edgeDevices.view.twin')}</h6>
              <span>{item.twinName}</span>
            </div>
          </div>

          {item?.deviceType ? (
            <div className={cx(classes.tile, '')}>
              <div className={cx(classes.statusesBlock, 'text-truncate')}>
                <h6>{intl.t('edgeDevices.view.deviceType')}</h6>
                <span>{item?.deviceType}</span>
              </div>
            </div>
          ) : null}

          {item?.lastConfigured ? (
            <div className={cx(classes.tile, '')}>
              <div className={cx(classes.statusesBlock, 'text-truncate')}>
                <h6>{intl.t('edgeDevices.view.lastReconfigured')}</h6>
                <span>{item?.lastConfigured}</span>
                <span>{item?.lastConfiguredBy}</span>
              </div>
            </div>
          ) : null}

          {item?.lastStarted ? (
            <div className={cx(classes.tile, '')}>
              <div className={cx(classes.statusesBlock, 'text-truncate')}>
                <h6>{intl.t('edgeDevices.view.lastStarted')}</h6>
                <span>{item?.lastStarted}</span>
                <span>{item?.lastStartedBy}</span>
              </div>
            </div>
          ) : null }
        </div>

        <div className="position-relative">
          {item?.status === EdgeDeviceStatus.WaitingForRegistration ? <div className={classes.overlay} /> : null}
          <TableWidget
            title={intl.t('edgeDevices.common.modules')}
            titleIcon={<LegacyIcon className="me-2" name="wiz--edge--modules" width={20} height={20} />}
            tableColumns={columns}
            tableData={modules}
            tablePlaceholder={deployModulesBtn}
            hidePlaceholder={!deployModulesBtn}
            loading={module.isLoading || (isModulesLoading && isModulesFetching)}
            isReadOnly={isReadOnly}
          />

          {isDeployed ? (
            <div className={cx(classes.tilesRow, '')}>
              <div className={cx(classes.tile, '')} style={{ width: '25%' }}>
                <TableWidget
                  title={intl.t('edgeDevices.common.functions')}
                  titleIcon={<LegacyIcon className="me-2" name="wiz--edge--functions" width={20} height={20} />}
                  tableColumns={functionColumns}
                  tableData={functions}
                  onAddClick={() => setEditItem({ component: EdgeDevicesEntity.functions })}
                  loading={isFunctionsLoading}
                  isReadOnly={isReadOnly}
                />
              </div>

              <div className={cx(classes.tile, '')} style={{ width: '25%' }}>
                <TableWidget
                  title={intl.t('edgeDevices.common.consumers')}
                  titleIcon={<LegacyIcon className="me-2" name="wiz--edge--consumers" width={20} height={20} />}
                  tableColumns={consumerColumns}
                  tableData={consumers}
                  onAddClick={() => setEditItem({ component: EdgeDevicesEntity.consumers })}
                  loading={isConsumersLoading}
                  isReadOnly={isReadOnly}
                />
              </div>

              <div className={cx(classes.tile, '')} style={{ width: '25%' }}>
                <TableWidget
                  title={intl.t('edgeDevices.common.writers')}
                  titleIcon={<LegacyIcon className="me-2" name="wiz--edge--writers" width={20} height={20} />}
                  tableColumns={writerColumns}
                  tableData={writers}
                  onAddClick={() => setEditItem({ component: EdgeDevicesEntity.writers })}
                  loading={isWritersLoading}
                  isReadOnly={isReadOnly}
                />
              </div>

              <div className={cx(classes.tile, '')} style={{ width: '25%' }}>
                <TableWidget
                  title={intl.t('edgeDevices.common.triggers')}
                  titleIcon={<LegacyIcon className="me-2" name="wiz--edge--triggers" width={20} height={20} />}
                  tableColumns={triggerColumns}
                  tableData={triggers}
                  onAddClick={() => setEditItem({ component: EdgeDevicesEntity.triggers })}
                  loading={isTriggersLoading}
                  isReadOnly={isReadOnly}
                />
              </div>
            </div>
          ) : null}
          <EdgeLogs
            data={logs.data}
            onRefresh={logs.refetch}
            modules={modules}
            isLoading={logs.isLoading}
            onTimestampChange={handleTimestampChange}
            timestamp={timestamp}
          />
        </div>
      </div>
      {isPopupOpened ? (
        <Popup
          refDialog={refDialog}
          title={intl.t('edgeDevices.common.chooseEdgeDeviceType')}
          size="lg"
          onClose={() => setIsPopupOpened(false)}
        >
          <div className="d-flex align-items-center">
            <button
              className={cx(classes.btnConnection, 'me-3')}
              type="button"
              onClick={() => handleClickDeviceType(RunnerConnectionType.Connectivity)}
            >
              <LegacyIcon className="me-2" name="wiz--menu--edge-devices" width={20} height={20} />
              &nbsp;
              {intl.t('edgeDevices.common.connectionOnly')}
            </button>
            {intl.t('form.fields.or')}
            <button
              className={cx(classes.btnConnection, 'ms-3', {
                [classes.locked]: !isProLicense,
              })}
              type="button"
              onClick={handleLicense}
            >
              {isProLicense ? null : (
                <>
                  <div className={classes.noLicense}>
                    <Icon name="faPuzzlePiece" className="me-1" size="md" />
                    <span>{intl.t('licenseManagement.title.addOn')}</span>
                  </div>
                  <Icon name="faLockKeyhole" className="mx-1" size="md" />
                </>

              )}
              <LegacyIcon className="ms-2" name="wiz--menu--edge-devices" width={20} height={20} />
              <Icon name="faPlus" className="mx-2" size="lg" />
              <LegacyIcon name="wiz--menu--pipeline" width={20} height={20} />
              &nbsp;
              <span className="ms-2">{intl.t('edgeDevices.common.connectionAndPipelines')}</span>
            </button>
          </div>
        </Popup>
      ) : null}
      {isLicensePopupOpen ? (
        <LicensePopup
          ref={refLicenseDialog}
          onClose={() => setLicensePopupOpen(false)}
          className={classes.content}
        />
      ) : null}
      {editItem?.component === EdgeDevicesEntity.functions ? (
        <FormEdgeFunction
          onClose={() => setEditItem(null)}
          id={editItem?.id}
          deviceId={id}
          dialog={{
            title: editItem?.id ? intl.t('edgeDevices.function.titleUpdate') :
              intl.t('edgeDevices.function.titleCreate'),
            dataTestid: 'replaceEdgeDevicesFunctionDialog',
          }}
        />
      ) : null}

      {editItem?.component === EdgeDevicesEntity.consumers ? (
        <FormEdgeConsumer
          id={editItem?.id}
          deviceId={id}
          onClose={() => setEditItem(null)}
          dialog={{
            title: editItem?.id ? intl.t('edgeDevices.consumer.titleUpdate') :
              intl.t('edgeDevices.consumer.titleCreate'),
            dataTestid: 'replaceEdgeDevicesConsumerDialog',
          }}
        />
      ) : null}

      {editItem?.component === EdgeDevicesEntity.writers ? (
        <FormEdgeWriter
          id={editItem.id}
          deviceId={id}
          onClose={() => setEditItem(null)}
          dialog={{
            title: editItem?.id ? intl.t('edgeDevices.writer.titleUpdate') : intl.t('edgeDevices.writer.titleCreate'),
            dataTestid: 'replaceEdgeDevicesWriterDialog',
          }}
        />
      ) : null}

      {editItem?.component === EdgeDevicesEntity.triggers ? (
        <FormEdgeTrigger
          id={editItem?.id}
          deviceId={id}
          onClose={() => setEditItem(null)}
          dialog={{
            title: editItem?.id ? intl.t('edgeDevices.trigger.titleUpdate') : intl.t('edgeDevices.trigger.titleCreate'),
            dataTestid: 'replaceEdgeDevicesTriggerDialog',
          }}
        />
      ) : null}
    </CustomScrollbars>
  )
}
