import { useMemo } from 'react'
import { useQuery, useMutation } from '@tanstack/react-query'
import { wizataApi } from '@/api'
import events from '@/utils/events'

const useLicense = () => {
  const licenseManagement = useQuery({
    queryKey: [ 'licenseManagement' ],
    queryFn: () => wizataApi.licenseManagement.getFeatures(),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: true,
  })

  const confirmRequest = useMutation({
    mutationKey: [ 'confirmLicenseRequest' ],
    mutationFn: data => wizataApi.licenseManagement.sendRequest(data),
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/licenseManagement.errors.confirmRequest',
        message: err.message,
        duration: 5000,
      })
    },
  })

  const isProLicense = useMemo(
    () => (
      licenseManagement.data?.licenseType ? licenseManagement.data.licenseType.toLowerCase() !== 'standard' : false),
    [ licenseManagement ],
  )

  return { isProLicense, licenseManagement, confirmRequest }
}

export default useLicense
