import { useEffect, useMemo } from 'react'
import { VirtualTable, Pagination } from '@wiz/components'
import Link from '@/components/Link'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import useAppContext from '@/hooks/useAppContext'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import useLicense from '@/pages/operate/hooks/useLicense'
import CellTwin from './CellTwin'

export default function Table ({
  items,
  onRemove,
  onEdit,
  onDuplicate,
  filters,
  refetch,
  ...props
}) {
  const intl = useIntl()
  const auth = useAuth()
  const { onRowSelect } = useAppContext()
  const { isProLicense } = useLicense()

  const columns = useMemo(() => [
    {
      Header: intl.t('form.fields.key'),
      accessor: 'deviceId',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 200,
      Cell: ({ cell, row }) => (cell.value ? (
        <div className="min-w-0 text-truncate">
          <Link
            className="min-w-0 text-truncate"
            title={cell.value}
            name="edge-device-view"
            params={{ id: row.original.id }}
          >
            {cell.value}
          </Link>
        </div>
      ) : '-'),
    },
    {
      Header: intl.t('form.fields.status'),
      accessor: 'status',
      Cell: ({ cell }) => (
        <div className="text-truncate">{intl.t(`edgeDevices.status.${cell.value}`)}</div>
      ),
    },
    {
      Header: intl.t('form.fields.twin'),
      accessor: 'twinId',
      Cell: ({ cell }) => (
        <CellTwin
          twinId={cell.value}
        />
      ),
    },
    {
      Header: intl.t('form.fields.updated'),
      accessor: 'updatedDate',
      disableResizing: true,
      minWidth: 200,
      width: 200,
      maxWidth: 220,
      Cell: ({ row }) => (
        <UpdatedInfo
          date={row.original.updatedDate}
          userId={row.original.updatedById}
          user={row.original.updatedUser}
        />
      ),
    },
    {
      Header: intl.t('form.fields.created'),
      accessor: 'createdDate',
      disableResizing: true,
      minWidth: 200,
      width: 200,
      maxWidth: 220,
      Cell: ({ row }) => (
        <UpdatedInfo
          date={row.original.createdDate}
          userId={row.original.createdById}
          user={row.original.createdUser}
        />
      ),
    },
  ], [ intl ])

  useEffect(() => {
    if (items && !props.loading) {
      refetch()
    }
  }, [
    filters.search,
    filters.page,
    filters.pageSize,
    filters.sortDir,
    filters.sortBy,
  ])

  return (
    <>
      <VirtualTable
        className="flex-fill mb-3"
        placeholder={intl.t('errors.noDataDisplay')}
        columns={columns}
        data={items}
        selection={isProLicense}
        {...props}
        onSelect={onRowSelect}
      />

      <Pagination {...props} />
    </>
  )
}
