import { useState, useRef } from 'react'
import { useIntl } from '@wiz/intl'
import Icon from '@/shared/icon'
import LicensePopup from '@/shared/licensePopup'

const EdgeDeviceActions = () => {
  const [ isPopupOpened, setIsPopupOpened ] = useState(false)
  const refDialog = useRef()

  const intl = useIntl()

  return (
    <>
      <button
        type="button"
        className="btn btn-fill-secondary-alt btn-text me-2"
        onClick={() => setIsPopupOpened(!isPopupOpened)}
      >
        <Icon name="faCrown" className="me-2" />
        {intl.t('edgeDevices.view.upgradeIncentive')}
      </button>

      {isPopupOpened ? (
        <LicensePopup
          ref={refDialog}
          onClose={() => setIsPopupOpened(false)}
          // className={classes.content}
        />
      ) : null}
    </>

  )
}

export default EdgeDeviceActions
